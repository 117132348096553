import { isNotNil } from '@shared/utils';

import {
  isBackgroundUnavailabilityComponent,
  isPunchComponent,
} from '@ManagerPortal/components/Scheduler/utils';
import { AnyScheduleItemType } from '@ManagerPortal/containers/Schedule/Main/types';

export const sortByTruthiness = <T>(a: T, b: T) =>
  !a === !b ? 0 : !a ? 1 : -1;

export const presortNils = <T>(
  a: T,
  b: T,
  sortFunction?: (a: NonNullable<T>, b: NonNullable<T>) => number,
) =>
  isNotNil(a) && isNotNil(b) ? sortFunction?.(a, b) : sortByTruthiness(a, b);

// The funky 末 is used to sort the shifts that have no start time as last
// as it will be always sorted after time that is written with latin characters.
// TODO Michal Sadowski: This function is duplicating the logic from the
// schedule selectors and should be unified during a refactor.
export const getNonNulledItemTime = (item?: AnyScheduleItemType | null) => {
  // We want rows that have only agreement unavailability components to be sorted last.
  if (item && isBackgroundUnavailabilityComponent(item)) return '末';

  // Punches should be sorted by the begin time of the shift they belong to
  if (item && isPunchComponent(item) && item.shift)
    return item.shift.begin || '末';
  return item?.begin || '末';
};

export const sortByNonNulledItemTime = <T extends AnyScheduleItemType | null>(
  a?: T,
  b?: T,
) => {
  const initialComparison = getNonNulledItemTime(a).localeCompare(
    getNonNulledItemTime(b),
  );

  // The above logic is sufficient if either of the items is not a punch
  // or if the punches belong to different shifts.
  if (
    !a ||
    !b ||
    !isPunchComponent(a) ||
    !isPunchComponent(b) ||
    initialComparison !== 0
  )
    return initialComparison;

  // If both items are punches that belong to the same shift, we need to sort them by their own begin time:
  return a.begin.localeCompare(b.begin);
};
